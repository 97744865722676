var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form1"},[_c('form',[_c('v-row',{attrs:{"wrap":"","min-width":"350px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"headline"},[_vm._v("DATOS GENERALES")]),_c('h4',[_vm._v(" Datos generales de la empresa y del Responsable del/los tratamientos ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"Razón social","counter":100,"label":"Razón social *"},model:{value:(_vm.razonSocial),callback:function ($$v) {_vm.razonSocial=$$v},expression:"razonSocial"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"Nombre Comercial","counter":100,"label":"Nombre Comercial"},model:{value:(_vm.nombreComercial),callback:function ($$v) {_vm.nombreComercial=$$v},expression:"nombreComercial"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|nif_cif_nie"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"NIF/CIF","label":"NIF/NIE/CIF *"},model:{value:(_vm.nifCif),callback:function ($$v) {_vm.nifCif=$$v},expression:"nifCif"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"empleados","label":"Nº empleados *","hint":"Escribe la cantidad de empleados contratados"},model:{value:(_vm.nEmpleados),callback:function ($$v) {_vm.nEmpleados=$$v},expression:"nEmpleados"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"dirección","label":"Dirección *","hint":"Escribe la dirrección de tu empresa","counter":100},model:{value:(_vm.direccion),callback:function ($$v) {_vm.direccion=$$v},expression:"direccion"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"Localidad","label":"Localidad *"},model:{value:(_vm.localidad),callback:function ($$v) {_vm.localidad=$$v},expression:"localidad"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"min_value:01000|max_value:52999|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"Cód.Postal","label":"Cód.Postal *"},model:{value:(_vm.codPostal),callback:function ($$v) {_vm.codPostal=$$v},expression:"codPostal"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"Actividad principal","label":"Actividad principal de la empresa *","hint":"A qué se dedica su empresa"},model:{value:(_vm.oficio),callback:function ($$v) {_vm.oficio=$$v},expression:"oficio"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"representante legal","data-vv-as":"Representante legal","label":"Representante legal *"},model:{value:(_vm.repreLegal),callback:function ($$v) {_vm.repreLegal=$$v},expression:"repreLegal"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|nif_nie"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"nif del representante legal","label":"Nif del representante *","counter":9},model:{value:(_vm.repreLegalNif),callback:function ($$v) {_vm.repreLegalNif=$$v},expression:"repreLegalNif"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"persona de contacto para protección de datos","label":"Persona de contacto para protección de datos *","hint":"Introduce el nombre de la persona de contacto"},model:{value:(_vm.perConProcDat),callback:function ($$v) {_vm.perConProcDat=$$v},expression:"perConProcDat"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"name":"Código de distribuidor","label":"Código de distribuidor ","hint":"Introduce el Código de distribuidor"},model:{value:(_vm.codDis),callback:function ($$v) {_vm.codDis=$$v},expression:"codDis"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"numeric|min:9|max:9|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"Teléfono móvil","label":"Teléfono móvil *","counter":9},model:{value:(_vm.tlfMovil),callback:function ($$v) {_vm.tlfMovil=$$v},expression:"tlfMovil"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"numeric|min:9|max:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"Teléfono fijo","counter":9,"label":"Teléfono fijo"},model:{value:(_vm.tlfFijo),callback:function ($$v) {_vm.tlfFijo=$$v},expression:"tlfFijo"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"email","data-vv-as":"Email","label":"Email *"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"Importe adecuación inicial","label":"Importe adecuación inicial *"},model:{value:(_vm.impAdeIni),callback:function ($$v) {_vm.impAdeIni=$$v},expression:"impAdeIni"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"Importe mantenimiento","label":"Importe mantenimiento *"},model:{value:(_vm.impMan),callback:function ($$v) {_vm.impMan=$$v},expression:"impMan"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"IBAN|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"name":"Numero de cuenta","mask":"NN##-####-####-####-####-####","label":"Numero de cuenta para facturación (IBAN) *","counter":24},model:{value:(_vm.numeroCuenta),callback:function ($$v) {_vm.numeroCuenta=$$v},expression:"numeroCuenta"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"name":"regMerca","hint":"Si es una sociedad inscrita en el Registro Mercantil anota datos de inscripción","label":"Datos de inscripción del registro mercantil"},model:{value:(_vm.regMerca),callback:function ($$v) {_vm.regMerca=$$v},expression:"regMerca"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"value":_vm.error,"transition":"scale-transition","type":"error","outlined":""}},[_vm._v("Responde a todas las preguntas para continuar!")])],1)],1),_c('navButtons',{attrs:{"page":_vm.page,"colores":_vm.colores,"ver":_vm.ver,"primero":"true"},on:{"stepper":function($event){return _vm.$emit('stepper', $event)},"GC":function($event){return _vm.validateForm('C')},"GA":function($event){return _vm.validateForm('A')}}}),(_vm.pruebas)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.pasos,"label":"Saltar al paso...","outlined":""},model:{value:(_vm.pasoAIr),callback:function ($$v) {_vm.pasoAIr=$$v},expression:"pasoAIr"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"teal darken-1","dark":"","type":"button"},on:{"click":function($event){return _vm.irAPaso()}}},[_vm._v("Ir")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12"}},[_c('b',[_vm._v("-Los campos obligatorios se marcan con un *")])])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }