<template>
  <v-row wrap class="d-flex justify-center">
    <v-col>
      <h3 class="headline">DOCUMENTACIÓN SECTORIAL</h3>
      <h4>
        Si su sector se encuentra en la siguiente lista podrá complentar las
        preguntas especificas de su campo
      </h4>
    </v-col>

    <v-col cols="11" v-if="sectorLocked == false">
      <v-autocomplete
        v-model="sectoresEscogidos"
        :readonly="sectorLock == true"
        :items="sectoresLista"
        :item-disabled="(item)=>!sectores.find(sector=>sector.id == item.id)"
        filled
        chips
        color="blue-grey lighten-2"
        label="Selecciona"
        item-text="sector"
        item-value="id"
        multiple
      ></v-autocomplete>
    </v-col>

    <v-col
      cols="12"
      v-for="(sector,index) in sectoresEscogidos"
      :key="index"
    >
      <v-col cols="12">
        <v-card
          class=" display-1 text-uppercase font-weight-light"
          :color="colores.terciario"
          elevation="20"
          dark
          >{{ sectores.find((sec) => sec.id == sector).sector }}</v-card
        >
      </v-col>
      <v-col
        cols="12"
        v-for="(op, index1) in sectores.find((sec) => sec.id == sector)
          .preguntas"
        :key="index1"
      >
        <v-col cols="12">
          <v-col cols="12">
            <v-card dark :color="colores.primario">
              <v-card-text
                class="white--text  px-2 font-weight-bold text-uppercase"
                >{{ op.pregunta }}</v-card-text
              >
            </v-card>
          </v-col>

          <v-col cols="12" v-for="(opcion, index) in op.opciones" :key="index">
            <v-col cols="12">
              <v-card :color="colores.secundario" class="justify-center">
                <v-card-text
                  class="d-flex  justify-center align-center px-2 font-weight-bold"
                  >{{ opcion.opcion }}</v-card-text
                >
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-btn fab @click="op.valor = opcion.valor">
                <v-icon
                  large
                  :color="op.valor == opcion.valor ? 'green darken-3' : 'grey'"
                  >check_circle</v-icon
                >
              </v-btn>
            </v-col>
          </v-col>
        </v-col>
      </v-col>
    </v-col>
    <v-col cols="12">
      <v-alert
        :value="error"
        transition="scale-transition"
        type="error"
        outlined
        >Responde a todas las preguntas para continuar!</v-alert
      >
    </v-col>
    <navButtons
      :page="page"
      :colores="colores"
      v-on:stepper="$emit('stepper', $event)"
      v-on:GC="uploadForm('C')"
      v-on:GA="uploadForm('A')"
    ></navButtons>
    <!-- <v-col>
        <template v-if="ver == false">
          <v-btn color="error" type="button" @click="$emit('stepper','7')">Atras</v-btn>
          <v-btn
            color="teal darken-1"
            dark
            type="button"
            @click="validar('form-8',9)"
          >Guardar y Atras</v-btn>

          <v-btn :color="colores.primario" dark @click="validar('form-8',9)">Guardar y continuar</v-btn>
        </template>
        <template v-else>
          <div>
            <v-btn color="error" type="button" @click="$emit('stepper','7')">Atras</v-btn>
            <v-btn color="teal darken-1" dark type="button" @click="$emit('stepper','9')">Siguiente</v-btn>
          </div>
        </template>
      </v-col> -->
  </v-row>
</template>
<script>
export default {
  props: [
    "token",
    "url",
    "idFormulario",
    "guardar",
    "ver",
    "idSectores",
    "sectorLock",
    "paso8",
    "colores",
  ],
  data: () => ({
    page: 8,
    sectoresEscogidos: [],
    sectoresLista: [
      { header: "Pymes" },
      { sector: "Comercios", id: "1" },
      { sector: "Restaurantes", id: "2" },
      { sector: "Tiendas on-line", id: "3" },
      { sector: "Fabricantes", id: "4" },
      { sector: "Laboratorios", id: "5" },
      { sector: "Constructoras", id: "6" },
      { divider: true },
      { header: "Profesionales" },
      { sector: "Asesorias", id: "7" },
      { sector: "Fotógrafos", id: "8" },
      { sector: "Arquitectos", id: "9" },
      { sector: "Taxistas", id: "10" },
      { sector: "Abogados", id: "11" },
      { sector: "Talleres mecánicos", id: "12" },
      { sector: "veterinarios", id: "13" },
      { divider: true },
      { header: "Bienestar y salud" },
      { sector: "Farmacias", id: "14" },
      { sector: "Gimnasios y otros centros deportivos", id: "15" },
      { sector: "Fisioterapeutas", id: "16" },
      { sector: "Médicos", id: "17" },
      { sector: "Centros de estética", id: "18" },
      { sector: "Peluquerias", id: "19" },
      { sector: "Ópticas", id: "20" },
      { sector: "Clinicas dentales", id: "21" },
      { sector: "Psicólogos", id: "22" },
      { divider: true },
      { header: "Asociaciones" },
      { sector: "Asociaciones", id: "23" },
      { sector: "Clubes deportivos", id: "24" },
      { sector: "AMPAS", id: "25" },
      { sector: "Asociaciones festeras y culturales", id: "26" },
      { divider: true },
      { header: "Inmobiliario" },
      { sector: "Inmobiliarias", id: "27" },
      { sector: "viviendas turisticas", id: "28" },
      { sector: "Administradores de fincas", id: "29" },
      { sector: "Comunidades de propietarios", id: "30" },
      { divider: true },
      { header: "Otros" },
      { sector: "Escuelas infantiles", id: "31" },
      { sector: "Organizadores de eventos", id: "32" },
      { sector: "Academias y centros de formación", id: "33" },
    ],
    sectores: [
      {
        id: "1",
        sector: "Comercios",
        preguntas: [
          {
            id: 0,
            pregunta:
              "¿Utilizáis una tarjeta de fidelización para acumular puntos y ofrecer beneficios a clientes habituales?",
            opciones: [
              { opcion: "Sí. una propia.", valor: 0 },
              {
                opcion:
                  "Sí. una del grupo / de una asociación de la que somos miembros",
                valor: 1,
              },
              { opcion: "No.", valor: 2 },
            ],

            valor: null,
          },
          {
            id: 1,
            pregunta: "¿Entregáis productos a domicilio?",
            opciones: [
              { opcion: "Sí. Con empleados propios.", valor: 0 },
              { opcion: "Sí. Con empresa subcontratada.", valor: 1 },
              {
                opcion: "Sí. Pero solo con empresa de mensajería / correos.",
                valor: 2,
              },
              { opcion: "No. Sólo vendemos en tienda física.", valor: 3 },
            ],

            valor: null,
          },
          {
            id: 2,
            pregunta:
              "¿Colaboráis con una entidad financiera para financiar las ventas?",
            opciones: [
              { opcion: "Sí. Trabajamos con financieras.", valor: 0 },
              {
                opcion:
                  "Exclusivamente financiamos compras con tarjeta de crédito.",
                valor: 1,
              },
              { opcion: "No. no financiamos las ventas.", valor: 2 },
            ],

            valor: null,
          },
        ],
      },
      {
        id: "2",
        sector: "Restaurantes",
        preguntas: [
          {
            id: 0,
            pregunta:
              "¿Dónde anotáis las reservas directas si os llaman por teléfono?",
            opciones: [
              { opcion: "En una agenda / calendario en papel", valor: 0 },
              {
                opcion: "En una agenda / calendario informatizado.",
                valor: 1,
              },
              { opcion: "Directamente en el programa de gestión.", valor: 2 },
              { opcion: "No aceptamos reservas.", valor: 3 },
            ],

            valor: null,
          },
          {
            id: 1,
            pregunta: "¿Elaboráis presupuestos para eventos?",
            opciones: [
              { opcion: "Sí. Hacemos ofertas por escrito.", valor: 0 },
              {
                opcion:
                  "Informamos verbalmente o entregamos folletos sin personalizar.",
                valor: 1,
              },
              {
                opcion: "No. No celebramos eventos organizados.",
                valor: 2,
              },
            ],

            valor: null,
          },
        ],
      },
      {
        id: "3",
        sector: "tiendas online",
        preguntas: [
          {
            id: 0,
            pregunta: "¿Tenéis almacén?",
            opciones: [
              {
                opcion: "Sí. Enviamos productos desde nuestro almacén.",
                valor: 0,
              },
              {
                opcion:
                  "No. Trabajamos con un operador logístico que almacena los productos y hace los envíos.",
                valor: 1,
              },
              {
                opcion:
                  "No. Trabajamos con el modelo de dropshipping (pasamos los pedidos al proveedor y los sirve con nuestra etiqueta)",
                valor: 2,
              },
            ],

            valor: null,
          },
        ],
      },
      {
        id: "5",
        sector: "laboratorios",
        preguntas: [
          {
            id: 0,
            pregunta: "¿Realizáis análisis de muestras de orina / sangre… con fines sanitarios?",
            opciones: [
              {
                opcion: "Sí. Realizamos ese tipo de análisis.",
                valor: 0,
              },
              {
                opcion:
                  "No. No realizamos análisis con fines de salud.",
                valor: 1,
              },
              
            ],

            valor: null,
          },
        ],
      },
      
    ],
    error: false,
    dataPost: [],
  }),
  mounted() {
    if (this.sectorLocked) this.sectoresEscogidos = this.idSectores;
  },
  computed: {
    sectorLocked() {
      return this.idSectores.length > 0;
    },
  },
  methods: {
    async validateForm() {
      if (this.sectoresEscogidos.length == 0) return true;
      for (const sectorElegido of this.sectoresEscogidos) {
        const preguntasSector = this.sectores.find(
          (sector) => sector.id == sectorElegido
        ).preguntas;
        for (const pregunta of preguntasSector) {
          if (pregunta.valor == null) return false;
        }
      }
      return true;
      // for (let index = 0; index < this.sectores.length; index++) {
      //   const sector = this.sectores[index];
      //   if (this.sectoresEscogidos.includes(sector.id)) {
      //     for (let index = 0; index < sector.preguntas.length; index++) {
      //       const pregunta = sector.preguntas[index];
      //       if (pregunta.valor == null) {
      //         this.error = true;
      //         return false;
      //       }
      //     }
      //   }
      // }

      //   for (let index = 0; index < this.sectores.length; index++) {
      //     const sector = this.sectores[index];
      //     if (this.sectoresEscogidos.includes(sector.id)) {
      //       let preguntas = [];
      //       for (let index2 = 0; index2 < sector.preguntas.length; index2++) {
      //         const pregunta = sector.preguntas[index2];
      //         preguntas.push({
      //           idPregunta: sector.preguntas.id,
      //           valor: pregunta.valor,
      //         });
      //       }
      //       this.dataPost.push({ sector: sector.id, preguntas: preguntas });
      //     }
      //   }
      // return true;
    },
    getAnswers() {
      let data = [];
      for (const sector of this.sectoresEscogidos) {
        let respuestasSector = { sector: sector, respuestas: [] };
        let preguntasRespuestasSector = this.sectores.find(
          (sectorActivado) => sectorActivado.id == sector
        );
        for (const pregunta of preguntasRespuestasSector.preguntas) {
          respuestasSector.respuestas.push({
            preguntaId: pregunta.id,
            valor: pregunta.valor,
          });
        }
        data.push(respuestasSector);
      }
      return data;
    },
    async uploadForm(type) {
      const isValid = await this.validateForm();
      console.log(isValid);
      if (!isValid) {
        this.error = true;
        return false;
      }
      try {
        await this.axios.put(this.url + "paso8", {
          data: this.getAnswers(),
          id: this.idFormulario,
          token: this.token,
        });
        if (type === "C") {
          this.$emit("stepper", this.page + 1);
        }
        if (type === "A") {
          this.$emit("stepper", this.page - 1);
        }
        return true;
      } catch (error) {
        this.error = true;
        console.log(error);
        return false;
      }
    },
    // validateForm(scope,aXPaso){

    //   this.dataPost = [];
    //   let sectores = Object.values(this.sectores);

    //   for (let index = 0; index < sectores.length; index++) {
    //     const sector = sectores[index];

    //     if (this.sectoresEscogidos.includes(sector.id)) {
    //       let datos = Object.entries(sector.datos);
    //       this.dataPost.push({id: sector.id, datos: datos});
    //       for (let index = 0; index < datos.length; index++) {
    //         const dato = datos[index];
    //         if(dato[1] == null){
    //           this.error = true;
    //           return;
    //         }

    //       }

    //     }

    //   }

    // },
  },
  watch: {
    error() {
      setTimeout(() => {
        this.error = false;
      }, 3000);
    },
    ver(val) {
      if (val == true) {
        this.sectorLocked = true;
      }
    },
    paso8(data) {
      this.sectoresEscogidos = [];
      // val = JSON.parse(val);
      console.log(data);
      let sectoresGuardados = [];
      for (const sector of data) {
        sectoresGuardados.push(sector.sector);
      }
      this.sectoresEscogidos = sectoresGuardados;
      for (const sector of this.sectores) {
        if (this.sectoresEscogidos.includes(sector.id)) {
          for (let pregunta of sector.preguntas) {
            let valorRespuestasData = data
              .find((sectorData) => sectorData.sector == sector.id)
              .respuestas.find(
                (respuestaData) => respuestaData.preguntaId == pregunta.id
              ).valor;
            pregunta.valor = valorRespuestasData;
          }
        }
      }
      // for (let index = 0; index < val.length; index++) {
      //   const sector = val[index];
      //   this.sectoresEscogidos.push(sector.sector);
      //   for (let index = 0; index < sector.preguntas.length; index++) {
      //     const preguntaVal = sector.preguntas[index];
      //     for (let index = 0; index < this.sectores.length; index++) {
      //       const sector = this.sectores[index];
      //       if (this.sectoresEscogidos.includes(sector.id)) {
      //         for (let index = 0; index < sector.preguntas.length; index++) {
      //           const pregunta = sector.preguntas[index];
      //           pregunta.valor = preguntaVal.valor;
      //         }
      //       }
      //     }
      //   }
      // }
    },
    guardar(val) {
      if (val == true) {
        this.validateForm("form-8");
      }
    },
  },
};
</script>
