<template>

        <v-toolbar dark :color="colores.primario" min-width="350px" >
          <v-toolbar-title  v-text="titulo" class="d-none d-lg-flex"></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title class="d-none d-sm-flex" >Formulario:</v-toolbar-title>
           <v-spacer></v-spacer>
          <template v-if="ver == false">
<v-btn  dark text @click="guardar()" class="d-flex pa-0" color="white">Guardar</v-btn>
 <v-spacer></v-spacer>
<h1  >|</h1>
          </template>
          
                       <v-spacer></v-spacer>

           <v-btn  dark text @click="recuperar()" class="d-flex pa-0" color="white">Recuperar</v-btn>
            <v-spacer></v-spacer>
            <h1  >|</h1>
             <v-spacer></v-spacer>
            <v-btn  dark text @click="nuevo()" class="d-flex pa-0" color="white">Nuevo</v-btn>
            <v-spacer></v-spacer>
          <v-toolbar-items>
            
    

            
          </v-toolbar-items>
          </v-toolbar>
    
</template>
<script>

 

export default {
  props:["titulo", "ver","colores"],
    data() {
      return {
        
      }
    },
    methods:{
      guardar(){
        this.$emit("guardar");
      },
      recuperar(){
        this.$emit("recuperar");
      },
      nuevo(){
        this.$emit("nuevo");
      }
    }
}
</script>