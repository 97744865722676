<template>
  <form
    novalidate
    @submit.prevent="validateForm('form-4', 5)"
    data-vv-scope="form-4"
  >
    <v-row wrap>
      <v-col cols="12">
        <h3 class="headline">VIDEOVIGILANCIA</h3>
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col cols="12">
        <v-card dark :color="colores.terciario" class="d-flex  justify-center align-center justify-center align-center">
          <v-card-text class="white--text  px-2 font-weight-bold text-uppercase"
            >Responde</v-card-text
          >
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card
          :color="colores.secundario"
          class="d-flex  justify-center align-center justify-center align-center"
        >
          <v-card-text
            class="d-flex  justify-center align-center px-2 font-weight-bold   justify-center align-center"
            >¿Dispone de un sistema de videovigilancia?</v-card-text
          >
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-row>
          <v-col cols="6">
            <v-btn fab @click="datos.videoVigilancia.dispone = 1">
              <v-icon
                large
                :color="
                  datos.videoVigilancia.dispone == 1 ? 'green darken-3' : 'grey'
                "
                >check_circle</v-icon
              >
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn fab @click="datos.videoVigilancia.dispone = 0">
              <v-icon
                large
                :color="datos.videoVigilancia.dispone == 0 ? 'error' : 'grey'"
                >cancel</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template v-if="datos.videoVigilancia.dispone == 1">
      <v-row wrap>
        <v-col cols="12" sm="6">
          <v-card
            :color="colores.secundario"
            class="d-flex  justify-center align-center justify-center align-center"
          >
            <v-card-text
              class="d-flex  justify-center align-center  px-2 font-weight-bold"
              >¿Las cámaras enfocan a la vía pública?</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="6">
              <v-btn fab @click="datos.videoVigilancia.enfocanvia = 1">
                <v-icon
                  large
                  :color="
                    datos.videoVigilancia.enfocanvia == 1
                      ? 'green darken-3'
                      : 'grey'
                  "
                  >check_circle</v-icon
                >
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn fab @click="datos.videoVigilancia.enfocanvia = 0">
                <v-icon
                  large
                  :color="
                    datos.videoVigilancia.enfocanvia == 0 ? 'error' : 'grey'
                  "
                  >cancel</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card
            :color="colores.terciario"
            class="d-flex  justify-center align-center justify-center align-center"
          >
            <v-card-text
              class="d-flex  justify-center align-center justify-center align-center white--text  px-2 font-weight-bold"
              >¿Cómo visualiza las imágenes?</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="order-1 order-sm-1">
          <v-card
            :color="colores.secundario"
            class="d-flex  justify-center align-center justify-center align-center"
            height="100%"
          >
            <v-card-text
              class="d-flex  justify-center align-center justify-center align-center  px-2 font-weight-bold"
              >Las vemos continuamente, en uno o varios monitores/
              TV?</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" class="order-3 order-sm-2">
          <v-card
            :color="colores.secundario"
            class="d-flex  justify-center align-center  justify-center"
            height="100%"
          >
            <v-card-text
              class="d-flex  justify-center align-center justify-center align-center  px-2 font-weight-bold "
            >
              No las vemos todo el tiempo,
              <br />sólo vemos las imágenes cuando hay un motivo.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="order-2 order-sm-3d-flex  justify-center align-center justify-center align-center"
          height="100%"
        >
          <v-btn fab @click="datos.videoVigilancia.continuamente = 1">
            <v-icon
              large
              :color="
                datos.videoVigilancia.continuamente == 1
                  ? 'green darken-3'
                  : 'grey'
              "
              >check_circle</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="order-4 order-sm-4" height="100%">
          <v-btn fab @click="datos.videoVigilancia.continuamente = 0">
            <v-icon
              large
              :color="
                datos.videoVigilancia.continuamente == 0
                  ? 'green darken-3'
                  : 'grey'
              "
              >check_circle</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <!-- acceso a las grabaciones de las camaras  -->

        <v-col cols="12">
          <v-card
            :color="colores.terciario"
            class="d-flex  justify-center align-center"
          >
            <v-card-text
              class="justify-center align-center white--text align-center  px-2 font-weight-bold"
              >El proveedor de videovigilancia ¿tiene acceso a las grabaciones
              de las cámaras?</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card
            :color="colores.secundario"
            class="d-flex  justify-center align-center"
            height="100%"
          >
            <v-card-text
              class="justify-center align-center  justify-center align-center  px-2 font-weight-bold"
              >Sí. <br />Tienen acceso para ver las imágenes si salta alguna
              alarma.</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            :color="colores.secundario"
            class="d-flex  justify-center align-center"
            height="100%"
          >
            <v-card-text
              class="justify-center align-center justify-center align-center px-2 font-weight-bold"
            >
              No.
              <br />En caso de alarma sólo vemos las imágenes personal de la
              empresa.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-btn fab @click="datos.videoVigilancia.accesoGraba = 1">
            <v-icon
              large
              :color="
                datos.videoVigilancia.accesoGraba == 1
                  ? 'green darken-3'
                  : 'grey'
              "
              >check_circle</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn fab @click="datos.videoVigilancia.accesoGraba = 0">
            <v-icon
              large
              :color="
                datos.videoVigilancia.accesoGraba == 0
                  ? 'green darken-3'
                  : 'grey'
              "
              >check_circle</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card
            :color="colores.terciario"
            class="d-flex  justify-center align-center"
          >
            <v-card-text
              class="d-flex white--text justify-center align-center px-2 font-weight-bold"
              >¿Durante cuántos días permanecen las imágenes en el
              grabador?</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card
            :color="colores.secundario"
            class="d-flex  justify-center align-center"
          >
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >7 días o menos</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            :color="colores.secundario"
            class="d-flex  justify-center align-center"
          >
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >Entre 7 y 30 días</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-btn fab @click="datos.videoVigilancia.tiempoPermanece = 0">
            <v-icon
              large
              :color="
                datos.videoVigilancia.tiempoPermanece == 0
                  ? 'green darken-3'
                  : 'grey'
              "
              >check_circle</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn fab @click="datos.videoVigilancia.tiempoPermanece = 1">
            <v-icon
              large
              :color="
                datos.videoVigilancia.tiempoPermanece == 1
                  ? 'green darken-3'
                  : 'grey'
              "
              >check_circle</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <v-row wrap>
      <v-col cols="12">
        <h3>REGISTRO DE LA JORNADA LABORAL</h3>
      </v-col>

      <v-col cols="12">
        <v-card
          :color="colores.terciario"
          class="d-flex  justify-center align-center"
        >
          <v-card-text
            class="d-flex  justify-center white--text align-center px-2 font-weight-bold"
            >¿Cómo registran la jornada laboral?</v-card-text
          >
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" class="order-1 order-sm-1">
        <v-card
          :color="colores.secundario"
          class="d-flex  justify-center align-center"
          height="100%"
        >
          <v-card-text
            class="d-flex  justify-center align-center px-2 font-weight-bold"
            >En papel.</v-card-text
          >
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" class="order-3 order-sm-2">
        <v-card
          :color="colores.secundario"
          class="d-flex  justify-center align-center"
          height="100%"
        >
          <v-card-text
            class="d-flex  justify-center align-center px-2 font-weight-bold"
            >Aplicación móvil de terceros.</v-card-text
          >
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" class="order-5 order-sm-3">
        <v-card
          :color="colores.secundario"
          class="d-flex  justify-center align-center"
          height="100%"
        >
          <v-card-text
            class="d-flex  justify-center align-center px-2 font-weight-bold"
          >
            Sistema biométrico
            <br />(huella dactilar, iris o reconocimiento facial)
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" class="order-2 order-sm-4">
        <v-btn fab @click="datos.jornadaLaboral.opcion = 1">
          <v-icon
            large
            :color="
              datos.jornadaLaboral.opcion == 1 ? 'green darken-3' : 'grey'
            "
            >check_circle</v-icon
          >
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" class="order-4 order-sm-5">
        <v-btn fab @click="datos.jornadaLaboral.opcion = 2">
          <v-icon
            large
            :color="
              datos.jornadaLaboral.opcion == 2 ? 'green darken-3' : 'grey'
            "
            >check_circle</v-icon
          >
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" class="order-6 order-sm-6">
        <v-btn fab @click="datos.jornadaLaboral.opcion = 3">
          <v-icon
            large
            :color="
              datos.jornadaLaboral.opcion == 3 ? 'green darken-3' : 'grey'
            "
            >check_circle</v-icon
          >
        </v-btn>
      </v-col>
    </v-row>
    <v-row wrap>
      <v-col cols="12">
        <h3>OTROS</h3>
      </v-col>

      <v-col cols="12">
        <v-card
          :color="colores.terciario"
          class="d-flex  justify-center align-center justify-center"
        >
          <v-card-text
            class="d-flex white--text justify-center align-center px-2 font-weight-bold"
            >¿Cómo prefiere que redactemos la política de privacidad para sus
            clientes?</v-card-text
          >
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          :color="colores.secundario"
          class="d-flex  justify-center align-center justify-center"
        >
          <v-card-text
            class="d-flex  justify-center align-center px-2 font-weight-bold"
            >DE TU</v-card-text
          >
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          :color="colores.secundario"
          class="d-flex  justify-center align-center justify-center"
        >
          <v-card-text
            class="d-flex  justify-center align-center px-2 font-weight-bold"
            >DE USTED</v-card-text
          >
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-btn fab @click="datos.otros.redactarPolitica.opcion = 1">
          <v-icon
            large
            :color="
              datos.otros.redactarPolitica.opcion == 1
                ? 'green darken-3'
                : 'grey'
            "
            >check_circle</v-icon
          >
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn fab @click="datos.otros.redactarPolitica.opcion = 2">
          <v-icon
            large
            :color="
              datos.otros.redactarPolitica.opcion == 2
                ? 'green darken-3'
                : 'grey'
            "
            >check_circle</v-icon
          >
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card
          :color="colores.terciario"
          class="d-flex  justify-center align-center justify-center"
        >
          <v-card-text
            class="d-flex white--text justify-center align-center px-2 font-weight-bold"
            >¿Utiliza fotografías de empleados para la promoción de su empresa
            en web / redes sociales o documentos impresos?
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          :color="colores.secundario"
          class="d-flex  justify-center align-center justify-center"
        >
          <v-card-text
            class="d-flex  justify-center align-center px-2 font-weight-bold"
            >Si</v-card-text
          >
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          :color="colores.secundario"
          class="d-flex  justify-center align-center justify-center"
        >
          <v-card-text
            class="d-flex  justify-center align-center px-2 font-weight-bold"
            >No</v-card-text
          >
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-btn fab @click="datos.otros.fotoEmpleados.opcion = 1">
          <v-icon
            large
            :color="
              datos.otros.fotoEmpleados.opcion == 1 ? 'green darken-3' : 'grey'
            "
            >check_circle</v-icon
          >
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn fab @click="datos.otros.fotoEmpleados.opcion = 2">
          <v-icon
            large
            :color="
              datos.otros.fotoEmpleados.opcion == 2 ? 'green darken-3' : 'grey'
            "
            >check_circle</v-icon
          >
        </v-btn>
      </v-col>
    </v-row>

    <v-alert v-if="error" transition="scale-transition" type="error" outlined
      >Responde a todas las preguntas para continuar!</v-alert
    >
    <navButtons
      :page="page"
      :colores="colores"
      v-on:stepper="$emit('stepper', $event)"
      v-on:GC="uploadForm('C')"
      v-on:GA="uploadForm('A')"
    ></navButtons>
    <!-- <template v-if="ver == false">
      <v-btn color="error" type="button" @click="$emit('stepper', '3')"
        >Atras</v-btn
      >
      <v-btn
        color="teal darken-1"
        dark
        type="button"
        @click="uploadForm('form-4', 3)"
        >Guardar y Atras</v-btn
      >

      <v-btn :color="colores.primario" dark type="submit"
        >Guardar y continuar</v-btn
      >
    </template>
    <template v-else>
      <v-btn color="error" type="button" @click="$emit('stepper', '3')"
        >Atras</v-btn
      >
      <v-btn
        color="teal darken-1"
        dark
        type="button"
        @click="$emit('stepper', '5')"
        >Siguiente</v-btn
      >
    </template> -->
  </form>
</template>
<script>
export default {
  props: ["token", "url", "paso4", "idFormulario", "guardar", "ver", "colores"],
  data: () => ({
    page: 4,
    datos: {
      videoVigilancia: {
        dispone: null,
        enfocanvia: null,
        continuamente: null,
        tiempoPermanece: null,
        accesoGraba: null,
      },
      jornadaLaboral: {
        opcion: null,
      },
      otros: {
        redactarPolitica: {
          opcion: null,
        },
        fotoEmpleados: {
          opcion: null,
        },
      },
    },

    error: 0,
  }),
  methods: {
    async validateForm() {
      if (
        this.datos.jornadaLaboral.opcion == null ||
        this.datos.otros.redactarPolitica.opcion == null ||
        this.datos.videoVigilancia.dispone == null ||
        this.datos.otros.fotoEmpleados.opcion == null
      ) {
        return false;
      }
      if (this.datos.videoVigilancia.dispone == true) {
        if (
          this.datos.videoVigilancia.enfocanvia == null ||
          this.datos.videoVigilancia.continuamente == null ||
          this.datos.videoVigilancia.tiempoPermanece == null ||
          this.datos.videoVigilancia.accesoGraba == null
        ) {
          this.error = true;
          return false;
        }
      }
      return true;
    },
    async uploadForm(type) {
      let isValid = await this.validateForm();
      if (!isValid) {
        this.error = true;
        return false;
      }
      try {
        await this.axios.put(this.url + "paso4", {
          token: this.token,
          id: this.idFormulario,
          data: this.datos,
        });
        if (type === "C") {
          this.$emit("stepper", this.page + 1);
          return true;
        }
        if (type === "A") {
          this.$emit("stepper", this.page - 1);
          return true;
        }
        return true;
      } catch (error) {
        console.log(error);
        this.error = false;
      }
    },
  },
  watch: {
    error() {
      setTimeout(() => {
        this.error = false;
      }, 3000);
    },
    paso4(datos) {
      this.datos = datos;
    },
    guardar(val) {
      if (val == true) {
        this.validateForm();
      }
    },
  },
};
</script>
