<template >
  <ValidationObserver  ref="form1">
    <form>
      <v-row wrap min-width="350px">
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <h3 class="headline">DATOS GENERALES</h3>
              <h4>
                Datos generales de la empresa y del Responsable del/los
                tratamientos
              </h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=12 sm=6>
              <ValidationProvider rules="required|max:100"  v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="Razón social"
                  v-model="razonSocial"
                  :counter="100"
                  label="Razón social *"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols=12 sm=6>
              <ValidationProvider rules="max:100" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="Nombre Comercial"
                  v-model="nombreComercial"
                  :counter="100"
                  label="Nombre Comercial"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols=12 sm=6>
              <ValidationProvider
                rules="required|nif_cif_nie"
                v-slot="{ errors }"
              >
                <v-text-field
                  :error-messages="errors[0]"
                  name="NIF/CIF"
                  v-model="nifCif"
                  label="NIF/NIE/CIF *"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols=12 sm=6>
              <ValidationProvider rules="numeric|required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="empleados"
                  v-model="nEmpleados"
                  label="Nº empleados *"
                  hint="Escribe la cantidad de empleados contratados"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ValidationProvider rules="required|max:100" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="dirección"
                  v-model="direccion"
                  label="Dirección *"
                  hint="Escribe la dirrección de tu empresa"
                  :counter="100"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=12 sm=6>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="Localidad"
                  v-model="localidad"
                  label="Localidad *"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols=12 sm=6>
              <ValidationProvider
                rules="min_value:01000|max_value:52999|required"
                v-slot="{ errors }"
              >
                <v-text-field
                  :error-messages="errors[0]"
                  name="Cód.Postal"
                  v-model="codPostal"
                  label="Cód.Postal *"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="Actividad principal"
                  v-model="oficio"
                  label="Actividad principal de la empresa *"
                  hint="A qué se dedica su empresa"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=12 sm=6>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="representante legal"
                  v-model="repreLegal"
                  data-vv-as="Representante legal"
                  label="Representante legal *"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols=12 sm=6>
              <ValidationProvider rules="required|nif_nie" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="nif del representante legal"
                  v-model="repreLegalNif"
                  label="Nif del representante *"
                  :counter="9"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=12 sm=6>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="persona de contacto para protección de datos"
                  v-model="perConProcDat"
                  label="Persona de contacto para protección de datos *"
                  hint="Introduce el nombre de la persona de contacto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols=12 sm=6>
              <v-text-field
                name="Código de distribuidor"
                v-model="codDis"
                label="Código de distribuidor "
                hint="Introduce el Código de distribuidor"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=12 sm=6>
              <ValidationProvider
                rules="numeric|min:9|max:9|required"
                v-slot="{ errors }"
              >
                <v-text-field
                  :error-messages="errors[0]"
                  name="Teléfono móvil"
                  v-model="tlfMovil"
                  label="Teléfono móvil *"
                  :counter="9"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols=12 sm=6>
              <ValidationProvider
                rules="numeric|min:9|max:9"
                v-slot="{ errors }"
              >
                <v-text-field
                  :error-messages="errors[0]"
                  name="Teléfono fijo"
                  v-model="tlfFijo"
                  :counter="9"
                  label="Teléfono fijo"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols=12 sm=12>
              <ValidationProvider rules="email|required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="email"
                  v-model="email"
                  data-vv-as="Email"
                  label="Email *"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=12 sm=6>
              <ValidationProvider rules="numeric|required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="Importe adecuación inicial"
                  v-model="impAdeIni"
                  label="Importe adecuación inicial *"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols=12 sm=6>
              <ValidationProvider rules="numeric|required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="Importe mantenimiento"
                  v-model="impMan"
                  label="Importe mantenimiento *"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ValidationProvider rules="IBAN|required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="Numero de cuenta"
                  v-model="numeroCuenta"
                  mask="NN##-####-####-####-####-####"
                  label="Numero de cuenta para facturación (IBAN) *"
                  :counter="24"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                name="regMerca"
                v-model="regMerca"
                hint="Si es una sociedad inscrita en el Registro Mercantil anota datos de inscripción"
                label="Datos de inscripción del registro mercantil"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row><v-col cols=12 class="d-flex justify-center"><v-alert
        :value="error"
        transition="scale-transition"
        type="error"
        outlined
        >Responde a todas las preguntas para continuar!</v-alert
      ></v-col></v-row>
          <navButtons
      :page="page"
      :colores="colores"
      :ver="ver"
      primero=true
      v-on:stepper="$emit('stepper', $event)"
      v-on:GC="validateForm('C')"
      v-on:GA="validateForm('A')"
    ></navButtons>
          <!-- <v-row v-if="ver == false">
            <v-col cols="12">
              <v-btn :color="colores.primario" dark type="submit"
                >Guardar y continuar</v-btn
              >
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col cols="12">
              <v-btn
                color="teal darken-1"
                dark
                type="button"
                :disabled="invalid"
                @click="$emit('stepper', '2')"
                >Siguiente</v-btn
              >
            </v-col>
          </v-row> -->

          <v-row v-if="pruebas">
            <v-col cols="12">
              <v-select
                :items="pasos"
                v-model="pasoAIr"
                label="Saltar al paso..."
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-btn color="teal darken-1" dark type="button" @click="irAPaso()"
                >Ir</v-btn
              >
            </v-col>
          </v-row>
          <v-row >
            <v-col cols=12 class="d-flex justify-start"><b>-Los campos obligatorios se marcan con un *</b></v-col>
          </v-row>
        </v-col>

        
      </v-row>
    </form>
  </ValidationObserver>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";

import {
  required,
  numeric,
  min,
  max,
  email,
  min_value,
  max_value,
} from "vee-validate/dist/rules";
import nif_cif_nie from "../validators/nif_cif_nie.js";
import nif_nie from "../validators/nif_nie.js";
import iban from "../validators/ibanValidator";
extend("nif_cif_nie", {
  ...nif_cif_nie,
  message: `La casilla debe ser un nif, nie o cif`,
});
extend("IBAN", {
  ...iban,
  message: `La casilla debe ser un IBAN valido`,
});
extend("min", {
  ...min,
  message: `El valor de la casilla debe de ser superior`,
});
extend("email", {
  ...email,
  message: `La casilla debe ser un email`,
});
extend("min_value", {
  ...min_value,
  message: `El valor de la casilla debe de ser superior`,
});
extend("max", {
  ...max,
  message: `El valor de la casilla debe de ser inferior`,
});
extend("max_value", {
  ...max_value,
  message: `El valor de la casilla debe de ser inferior`,
});
extend("nif_nie", {
  ...nif_nie,
  message: `La casilla debe ser un nif o nie`,
});
extend("numeric", {
  ...numeric,
  message: `La casilla debe ser un numero`,
});
extend("required", {
  ...required,
  message: `La casilla es requerida`,
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: [
    "token",
    "url",
    "paso1",
    "idFormulario",
    "guardar",
    "ver",
    "pruebas",
    "colores",
  ],
  $_veeValidate: {
    validator: "new",
  },

  data: () => ({
    error:false,
    page:1,
    pasoAIr: null,
    pasos: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    codigoSesion: null,
    razonSocial: "",
    nifCif: "",
    nEmpleados: null,
    direccion: "",
    localidad: "",
    codPostal: null,
    oficio: "",
    repreLegal: "",
    repreLegalNif: "",
    perConProcDat: "",
    tlfMovil: null,
    tlfFijo: "",
    email: "",
    impAdeIni: 295,
    impMan: 165,
    numeroCuenta: "",
    regMerca: "",
    codDis: "",
    nombreComercial: "",
  }),
  methods: {
    irAPaso() {
      this.$emit("stepper", this.pasoAIr);
    },
    async validateForm() {
      if(!await this.$refs.form1.validate()) return this.error=true 
      let me = this;
      console.log(await this.$refs.form1.validate())
      let data = {
        nombreComercial: me.nombreComercial,
        rSocial: me.razonSocial,
        nifCif: me.nifCif,
        nEmpleados: parseInt(me.nEmpleados, 10),
        dir: me.direccion,
        localidad: me.localidad,
        codPostal: me.codPostal,
        rLegal: me.repreLegal,
        actEmpresa: me.oficio,
        nifLegal: me.repreLegalNif,
        perConProcDat: me.perConProcDat,
        tlfMovil: me.tlfMovil,
        tlfFijo: me.tlfFijo,
        email: me.email,
        impAdeIni: me.impAdeIni,
        impMan: me.impMan,
        codDis: me.codDis,
        iban: me.numeroCuenta,
        regMerca: me.regMerca,
      };
      this.axios
        .put(me.url + "paso1", {
          token: me.token,
          data: data,
          id: me.idFormulario,
        })
        .then(function() {
          me.$emit("stepper", "2");
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  watch:{
    error() {
      setTimeout(() => {
        this.error = false;
      }, 3000);
    },
    paso1(datos) {
      this.nombreComercial = datos.nombreComercial;
      this.razonSocial = datos.rSocial;
      this.nifCif = datos.nifCif;
      this.nEmpleados = datos.nEmpleados;
      this.direccion = datos.dir;
      this.localidad = datos.localidad;
      this.codPostal = datos.codPostal;
      this.repreLegal = datos.rLegal;
      this.perConProcDat = datos.perConProcDat;
      this.oficio = datos.actEmpresa;
      this.repreLegalNif = datos.nifLegal;
      this.tlfMovil = datos.tlfMovil;
      this.tlfFijo = datos.tlfFijo;
      this.email = datos.email;
      this.impAdeIni = datos.impAdeIni;
      this.impMan = datos.impMan;
      this.codDis = datos.codDis;
      this.numeroCuenta = datos.iban;
      this.regMerca = datos.regMerca;
    },
  },
  
    
  //   guardar(val) {
  //     if (val == true) {
  //       this.validateForm();
  //     }
  //   },
  // },
  mounted() {},
};
</script>
