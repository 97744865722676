<template>
  <form>
    <v-row>
      <v-col>
        <h3 class="headline">IDENTIFICACIÓN DE LOS TRATAMIENTOS</h3>
        <h4>Por favor responda a estas preguntas.</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row row wrap>
          <v-col cols="12" class="d-md-none d-flex">
            <v-card
              width="100%"
              dark
              :color="colores.primario"
              class="d-flex justify-center align-center"
            >
              <v-card-text
                class="white--text  px-2 font-weight-bold text-uppercase "
                >Responde</v-card-text
              >
            </v-card>
          </v-col>
        </v-row>
        <v-row row wrap class="d-none d-md-flex">
          <v-col md="6">
            <v-card
              dark
              :color="colores.primario"
              height="100%"
              class="d-flex justify-center align-center"
            >
              <v-card-text
                class="white--text  px-2 font-weight-bold text-uppercase d-flex justify-center align-center"
                >Preguntas</v-card-text
              >
            </v-card>
          </v-col>
          <v-col md="3">
            <v-card
              dark
              :color="colores.primario"
              height="100%"
              class="d-flex justify-center align-center"
            >
              <v-card-text
                class="white--text  px-2 font-weight-bold text-uppercase d-flex justify-center align-center"
                >Respuesta</v-card-text
              >
            </v-card>
          </v-col>

          <v-col md="3">
            <v-card
              dark
              :color="colores.primario"
              height="100%"
              class="d-flex justify-center align-center"
            >
              <v-card-text
                class="white--text  px-2 font-weight-bold text-uppercase d-flex justify-center align-center"
                >Observaciones</v-card-text
              >
            </v-card>
          </v-col>
        </v-row>
        <v-row
          align-center
          justify-center
          row
          fill-height
          v-for="(pregun, index) in preguntas.pregunta"
          :key="index"
        >
          <v-col
            md="6"
            cols="12"
            class="d-flex mb-2 align-center justify-center"
          >
            <v-card
              :color="colores.secundario"
              width="100%"
              class="d-flex justify-center bold align-center"
            >
              <v-card-text
                class="d-flex  justify-center align-center px-2 font-weight-bold"
                >{{ pregun.pregunta }}</v-card-text
              >
            </v-card>
          </v-col>

          <v-col
            md="3"
            cols="12"
            class="mb-2 d-flex align-center justify-center justify-space-around"
          >
            <v-btn
              fab
              class="mx-2"
              @click="preguntas.datos[index].condicion = 1"
            >
              <v-icon
                large
                :color="
                  preguntas.datos[index].condicion == 1
                    ? 'green darken-3'
                    : 'grey'
                "
                >check_circle</v-icon
              >
            </v-btn>
            <v-btn fab @click="preguntas.datos[index].condicion = 0">
              <v-icon
                large
                :color="
                  preguntas.datos[index].condicion == 0 ? 'error' : 'grey'
                "
                >cancel</v-icon
              >
            </v-btn>
          </v-col>
          <v-col
            md="3"
            cols="12"
            height="1px"
            class="d-flex align-center justify-center"
          >
            <v-textarea
              rows="2"
              v-model="preguntas.datos[index].observaciones"
              label="Escribe aquí tus observaciones"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-alert
          :value="error"
          transition="scale-transition"
          type="error"
          outlined
          >Responde a todas las preguntas para continuar!
        </v-alert>
      </v-col>
    </v-row>
    <!-- <v-row>
    <v-col md=6 cols=12 >
  
<v-btn :color="colores.primario" dark type="submit"
        >Guardar y continuar</v-btn
      >
      
    </v-col>
    <v-spacer>

    </v-spacer>
    <v-col md=6 cols=12 >
       <v-btn color="teal darken-1" dark type="button" @click="validar(1)"
        >Guardar y Atras</v-btn
      >

    </v-col>
  </v-row>
  <v-row>
    <v-col cols=12>
<v-btn color="error" type="button" @click="$emit('stepper', '1')"
        >Atras</v-btn
      >
    </v-col>
   
  </v-row>
  <v-row v-if="ver">
    <v-col cols=6>
 <v-btn color="error" type="button" @click="$emit('stepper', '1')"
        >Atras</v-btn
      >
    </v-col>
    <v-col cols=6>
  <v-btn
        color="teal darken-1"
        dark
        type="button"
        @click="$emit('stepper', '3')"
        >Siguiente</v-btn
      >
    </v-col>

    
  </v-row> -->
    <navButtons
      :page="page"
      :colores="colores"
      v-on:stepper="$emit('stepper', $event)"
      v-on:GC="uploadForm('C')"
      v-on:GA="uploadForm('A')"
    ></navButtons>
  </form>
</template>
<script>
export default {
  components: {},

  props: ["token", "url", "paso2", "idFormulario", "guardar", "ver", "colores"],
  data: () => ({
    page: 2,
    preguntas: {
      pregunta: [
        {
          id: 1,
          pregunta: `¿Dispone de un sistema de gestión (para hacer presupuestos / facturas)? 
            De no ser así, coméntelo en las observaciones`,
        },
        {
          id: 2,
          pregunta: "¿Dispone de un programa para la contabilidad interna?",
        },
        {
          id: 3,
          pregunta:
            "¿Utiliza programas de ofimática (Word / Excel o similares)?",
        },
        {
          id: 4,
          pregunta:
            "¿Utiliza correo electrónico con clientes / proveedores / contactos?",
        },
        { id: 5, pregunta: "¿Guarda Currículum Vitae de candidatos?" },
        {
          id: 6,
          pregunta: "¿Utiliza remesas bancarias para pagar o cobrar en lotes?",
        },
        {
          id: 7,
          pregunta: "¿Tiene implantada la prevención de riesgos laborales?",
        },
        { id: 8, pregunta: "¿Puede cobrar mediante tarjeta de crédito?" },
      ],

      datos: [
        {
          id: 1,
          condicion: null,
          observaciones: "",
        },
        {
          id: 2,
          condicion: null,
          observaciones: "",
        },
        {
          id: 3,
          condicion: null,
          observaciones: "",
        },
        {
          id: 4,
          condicion: null,
          observaciones: "",
        },
        {
          id: 5,
          condicion: null,
          observaciones: "",
        },
        {
          id: 6,
          condicion: null,
          observaciones: "",
        },
        {
          id: 7,
          condicion: null,
          observaciones: "",
        },
        {
          id: 8,
          condicion: null,
          observaciones: "",
        },
      ],
    },

    error: false,
  }),
  methods: {
    validateForm() {
      for (const pregunta of this.preguntas.datos) {
        if (pregunta.condicion == null) {
          console.log(pregunta)
          return false;
        }
        
      }
      return true;

      // let me = this;
      // let preguntaC = 0;
      // for (let index = 0; index < this.preguntas.pregunta.length; index++) {
      //   const pregunta = this.preguntas.datos[index];

      //   if (pregunta.condicion == null) {
      //     this.errores = true;
      //   } else {
      //     preguntaC++;
      //   }
      //   if (preguntaC == 8) {
      //     Vue.axios
      //       .post(me.url + "paso2", {
      //         token: me.token,
      //         id: me.idFormulario,
      //         preguntas: me.preguntas.datos,
      //       })
      //       .then(function() {
      //         me.errores = false;
      //         me.$emit("stepper", aXPaso);
      //       })
      //       .catch(function(error) {
      //         console.log(error);
      //       });
      //   }
      // }
    },
    async uploadForm(type) {
      if (!this.validateForm()) {
        this.error = true;
        return false;
      }
      try {
        await this.axios.put(this.url + "paso2", {
          token: this.token,
          id: this.idFormulario,
          data: this.preguntas.datos,
        });
        if (type === "C") {
          this.$emit("stepper", this.page + 1);
        }
        if (type === "A") {
          this.$emit("stepper", this.page - 1);
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        return false;
      }
    },
    /*
    volverYGuardar(step){
      let me = this;
      let preguntaC = 0;
      for (let index = 0; index < this.preguntas.pregunta.length; index++) {
        const pregunta = this.preguntas.datos[index];

        if (pregunta.condicion == null) {
          this.errores = true;
        } else {
          preguntaC++;
        }
        if (preguntaC == 8) {
          Vue.axios
            .post(me.url + "paso2", {
              token: me.token,
              id: me.idFormulario,
              preguntas: me.preguntas.datos
            })
            .then(function(response) {
              me.errores = false;
              me.$emit("-stepper", step);
            })
            .catch(function(error) {

            });
        }
      }
    }*/
  },
  watch: {
    paso2(datos) {
      this.preguntas.datos = datos;
    },
    error() {
      setTimeout(() => {
        this.error = false;
      }, 3000);
    },
    guardar(val) {
      if (val == true) {
        this.validateForm();
      }
    },
    
  },
  
};
</script>
