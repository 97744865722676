<template>
  <v-app>
    <v-main>
      <toolbar
              :titulo="titulo"
              @guardar="guardarFormulario()"
              @recuperar="recuperar()"
              @nuevo="nuevo()"
              :ver="ver"
              :colores="colores"
          />
      <v-container fluid>
        
        <v-row class="d-flex justify-center">
          <v-col cols="12" class="d-flex justify-center px-10">
            <v-stepper v-model="step" vertical>
              <v-stepper-step class="subheading" step="1" :complete="step > 1"
                >DATOS GENERALES</v-stepper-step
              >

              <v-stepper-content step="1">
                <paso1
                  :guardar="guardarForm"
                  :token="token"
                  :url="url"
                  :ver="ver"
                  :colores="colores"
                  :pruebas="pruebas"
                  :idFormulario="idFormulario"
                  :paso1="datos.paso1"
                  v-on:stepper="changeStep($event)"
                />
              </v-stepper-content>

              <v-stepper-step step="2" class="subheading" :complete="step > 2"
                >IDENTIFICACIÓN DE LOS TRATAMIENTOS</v-stepper-step
              >

              <v-stepper-content step="2">
                <paso2
                  :token="token"
                  :guardar="guardarForm"
                  :colores="colores"
                  :paso2="datos.paso2"
                  :url="url"
                  :ver="ver"
                  :idFormulario="idFormulario"
                  v-on:stepper="changeStep($event)"
                />
              </v-stepper-content>

              <v-stepper-step step="3" class="subheading" :complete="step > 3"
                >RRHH</v-stepper-step
              >

              <v-stepper-content step="3">
                <paso3
                  :token="token"
                  :guardar="guardarForm"
                  :url="url"
                  :colores="colores"
                  :ver="ver"
                  :idFormulario="idFormulario"
                  :paso3="datos.paso3"
                  v-on:stepper="changeStep($event)"
                />
              </v-stepper-content>

              <v-stepper-step step="4" class="subheading" :complete="step > 4"
                >OTROS PROCESOS</v-stepper-step
              >

              <v-stepper-content step="4">
                <paso4
                  :token="token"
                  :guardar="guardarForm"
                  :url="url"
                  :ver="ver"
                  :idFormulario="idFormulario"
                  :paso4="datos.paso4"
                  :colores="colores"
                  v-on:stepper="changeStep($event)"
                />
              </v-stepper-content>
              <v-stepper-step step="5" class="subheading" :complete="step > 5"
                >INFORMÁTICA</v-stepper-step
              >

              <v-stepper-content step="5">
                <paso5
                  :paso5="datos.paso5"
                  :token="token"
                  :guardar="guardarForm"
                  :url="url"
                  :colores="colores"
                  :ver="ver"
                  :idFormulario="idFormulario"
                  v-on:stepper="changeStep($event)"
                />
              </v-stepper-content>
              <v-stepper-step step="6" class="subheading" :complete="step > 6"
                >PAPEL</v-stepper-step
              >
              <v-stepper-content step="6">
                <paso6
                  :paso6="datos.paso6"
                  :token="token"
                  :colores="colores"
                  :guardar="guardarForm"
                  :url="url"
                  :ver="ver"
                  :idFormulario="idFormulario"
                  v-on:stepper="changeStep($event)"
                />
              </v-stepper-content>
              <v-stepper-step step="7" class="subheading" :complete="step > 7"
                >CONTRATOS</v-stepper-step
              >
              <v-stepper-content step="7">
                <paso7
                  :token="token"
                  :colores="colores"
                  :guardar="guardarForm"
                  :paso7="datos.paso7"
                  :url="url"
                  :ver="ver"
                  :idFormulario="idFormulario"
                  v-on:stepper="changeStep($event)"
                />
              </v-stepper-content>

              <v-stepper-step step="8" class="subheading" :complete="step > 8"
                >SECTOR</v-stepper-step
              >
              <v-stepper-content step="8">
                <paso8
                  :token="token"
                  :guardar="guardarForm"
                  :url="url"
                  :ver="ver"
                  :idFormulario="idFormulario"
                  :paso8="datos.paso8"
                  :sectorLock="sectorLock"
                  :colores="colores"
                  :idSectores="idSectores"
                  v-on:stepper="changeStep($event)"
                />
              </v-stepper-content>
              <v-stepper-step step="9" class="subheading"
                >Completado</v-stepper-step
              >
              <v-stepper-content step="9">
                <completado
                  @nuevo="nuevo()"
                  :token="token"
                  :colores="colores"
                  :guardar="guardarForm"
                  :completado="datos.estado"
                  :url="url"
                  :path="path"
                  :ver="ver"
                  :idFormulario="idFormulario"
                  v-on:stepper="changeStep($event)"
                />
              </v-stepper-content>
            </v-stepper>
          </v-col>
        </v-row>

        <v-dialog v-model="guardarForm" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Guardar formulario</span>
            </v-card-title>
            <v-card-text>
              <p>
                Guardando estos datos podras recuperar los datos de este
                formulario en cualquier parte en la sección "Recuperar" o bien
                escogiendo la opción de Email en la que se te mandarán los dos
                datos a modo informativo y se te generará un link al formulario
                guardado.
              </p>
              <h4>
                <b
                  >Tambien puedes acceder al formulario mediante este enlace:</b
                >
              </h4>
              <p>
                <a>{{ path }}/{{ idFormulario }}/{{ token }}</a>
              </p>
              <p>
                <b>Los datos no validados no serán guardados.</b>
              </p>
              <v-tabs slider-color="yellow" fixed-tabs>
                <v-tab>Datos</v-tab>
                <v-tab>Email</v-tab>
                <v-tab-item>
                  <v-card>
                    <v-row wrap>
                      <v-col cols="12">
                        <v-text-field
                          label="ID"
                          readonly
                          v-model="idFormulario"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="TOKEN"
                          readonly
                          v-model="token"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <form data-vv-scope="guardarForm">
                      <v-row wrap>
                        <v-col cols="12">
                          <v-text-field
                            label="Email"
                            data-vv-as="email"
                            v-model="emailFormulario"
                          ></v-text-field>
                        </v-col>
                        <v-col text-xs-center>
                          <v-btn
                            :color="colores.primario"
                            dark
                            @click="enviarCorreo()"
                            >Enviar Correo</v-btn
                          >
                        </v-col>

                        <v-dialog
                          v-model="emailEnviadoLoader"
                          hide-overlay
                          persistent
                          width="300"
                        >
                          <v-card :color="colores.primario" dark>
                            <v-card-text>
                              Enviando email...
                              <v-progress-linear
                                indeterminate
                                color="white"
                                class="mb-0"
                              ></v-progress-linear>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </form>
                  </v-card>
                </v-tab-item>
              </v-tabs>
              <v-row>
                <v-col cols="12" class="d-flex mt-5 justify-center">
                  <v-btn color="red darken-1" text @click="descartarForm()"
                    >Descartar formulario</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="guardarForm = false"
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="recuperarForm" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Recuperar formulario</span>
            </v-card-title>
            <v-card-text>
              <p>
                Introduciendo estos datos podras recuperar de manera manual los
                datos del formulario previamente guardado en la sección
                "Guardar" y continuar editando.
              </p>

              <v-row wrap>
                <v-col cols="12">
                  <v-text-field
                    label="ID"
                    v-model="idFormularioRecuperar"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="TOKEN"
                    v-model="tokenRecuperar"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="recuperarFormu()"
                >Recuperar</v-btn
              >
              <v-btn color="red darken-1" text @click="recuperarForm = false"
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Swal from "sweetalert2";

import toolbar from "./toolbar.vue";
import paso1 from "./paso1.vue";
import paso2 from "./paso2.vue";
import paso3 from "./paso3.vue";
import paso4 from "./paso4.vue";
import paso5 from "./paso5.vue";
import paso6 from "./paso6.vue";
import paso7 from "./paso7.vue";
import paso8 from "./paso8.vue";
import completado from "./completado.vue";
import Vue from "vue";

export default {
  components: {
    toolbar,
    paso1,
    paso2,
    paso3,
    paso4,
    paso5,
    paso6,
    paso7,
    paso8,
    completado,
  },

  name: "App",
  data() {
    return {
      emailEnviadoLoader: false,
      guardar: false,
      emailFormulario: "",
      token: "",
      idFormulario: "",
      tokenRecuperar: "",
      idFormularioRecuperar: "",
      guardarForm: false,
      recuperarForm: false,
      url: "",
      urlAdelopd: "https://altas.adelopd.com/adelopd/",
      urlPruebas: "https://api.infocursos.es/adelopd/",
      urlGalan: "https://altas.adelopd.com/galan/",
      urlProteccion: "https://altas.protecciondatos.online/protecciondatos/",
      path: "",
      pathPruebas: "https://pruebas.infocursos.es/",
      pathAdelopd: "https://altas.adelopd.com/#/",
      pathProteccion: "https://altas.protecciondatos.online/#/",
      pathGalan: "https://altas.galanasociados.com/#/",
      titulo: "CUESTIONARIO GENERAL RGPD ADELOPD",
      ver: false,
      step: 1,
      formVersion: 2,
      formRecuperadoVer: "",
      datos: {
        version: null,
        estado: null,
        paso1: null,
        paso2: null,
        paso3: null,
        paso4: null,
        paso5: null,
        paso6: null,
        paso7: null,
        paso8: null,
      },
      sector: null,
      idSectores: [],
      sectorLock: false,
      pruebas: false,
      customizaciones: {
        adelopd: {
          primario: "#018A9C",
          secundario: "#ffc076",
          terciario: "#006064",
        },
        proteccion: {
          primario: "#006064",
          secundario: "#B0BEC5",
          terciario: "#006068",
        },
        galan: {
          primario: "#0d4661",
          secundario: "#a58b75",
          terciario: "#00b9e9",
        },
        otros: {
          primario: "##ffc076",
          secundario: "#046064",
          terciario: "#706064",
        },
      },
      colores: {
        primario: "",
        secundario: "",
        terciario: "",
      },
    };
  },
  computed: {},
  methods: {
    enviarCorreo() {
      let me = this;

      me.emailEnviadoLoader = true;
      this.axios
        .post(me.url + "enviarToken", {
          token: me.token,
          id: me.idFormulario,
          email: me.emailFormulario,
        })
        .then(function() {
          me.guardarForm = false;
          me.emailEnviadoLoader = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    nuevo() {
      localStorage.removeItem("token");
      localStorage.removeItem("idFormulario");
      location = this.path;
      location.reload();
    },

    recuperarFormu() {
      localStorage.token = this.tokenRecuperar;
      localStorage.idFormulario = this.idFormularioRecuperar;
      location = this.path;
      location.reload();
    },
    recuperar() {
      this.recuperarForm = !this.recuperarForm;
    },
    descartarForm() {
      let me = this;

      this.axios
        .put(this.url + "datos/cambiarestado", {
          token: me.token,
          id: me.idFormulario,
          estado: 3,
        })
        .then(function() {
          Swal.fire({
            type: "success",
            title: "Formulario descartado",
            text: "",
          });
          setTimeout(function() {
            location = me.pathAdelopd;
          }, 5000);
        });
    },

    guardarFormulario() {
      this.guardarForm = !this.guardarForm;
    },

    changeStep(event) {
      this.step = event;
    },
    sectores() {
      if (this.sector != null) {
        let arraySectores = this.sector.split(",");
        this.idSectores = [];

        for (let index = 0; index < arraySectores.length; index++) {
          const sector = arraySectores[index];
          switch (sector) {
            case "comercios":
              this.idSectores.push("1");
              break;
            case "restaurantes":
              this.idSectores.push("2");
              break;
            case "tiendas-online":
              this.idSectores.push("3");
              break;
            case "fabricantes":
              this.idSectores.push("4");
              break;
            case "laboratorios":
              this.idSectores.push("5");
              break;
            case "constructoras":
              this.idSectores.push("6");
              break;
            case "asesorias":
              this.idSectores.push("7");
              break;
            case "fotografos":
              this.idSectores.push("8");
              break;
            case "arquitectos":
              this.idSectores.push("9");
              break;
            case "taxistas":
              this.idSectores.push("10");
              break;
            case "abogados":
              this.idSectores.push("11");
              break;
            case "talleres-mecanicos":
              this.idSectores.push("12");
              break;
            case "veterinarios":
              this.idSectores.push("13");
              break;
            case "farmacias":
              this.idSectores.push("14");
              break;
            case "gimnasios":
              this.idSectores.push("15");
              break;
            case "fisioterapeutas":
              this.idSectores.push("16");
              break;
            case "medicos":
              this.idSectores.push("17");
              break;
            case "estetica":
              this.idSectores.push("18");
              break;
            case "peluquerias":
              this.idSectores.push("19");
              break;
            case "opticas":
              this.idSectores.push("20");
              break;
            case "clinicas-dentales":
              this.idSectores.push("21");
              break;
            case "psicólogos":
              this.idSectores.push("22");
              break;
            case "asociaciones":
              this.idSectores.push("23");
              break;
            case "clubes-deportivos":
              this.idSectores.push("24");
              break;
            case "ampas":
              this.idSectores.push("25");
              break;
            case "asociaciones-festeras-y-culturales":
              this.idSectores.push("26");
              break;
            case "inmobiliarias":
              this.idSectores.push("27");
              break;
            case "viviendas-turisticas":
              this.idSectores.push("28");
              break;
            case "administradores-de-fincas":
              this.idSectores.push("29");
              break;
            case "comunidades-de-propietarios":
              this.idSectores.push("30");
              break;
            case "esculas-infantiles":
              this.idSectores.push("31");
              break;
            case "organizadores-de-eventos":
              this.idSectores.push("32");
              break;
            case "academias-y-centros-de-formacion":
              this.idSectores.push("33");
              break;

            default:
              break;
          }
          this.sectorLock = true;
        }
      } else {
        this.sectorLock = false;
      }
    },
    setUrl() {
      const urlNavegador = window.location.origin;
      switch (urlNavegador) {
        case "https://altas.protecciondatos.online":
          this.url = this.urlProteccion;
          this.titulo = "CUESTIONARIO GENERAL RGPD PROTECCIÓN DATOS";
          this.path = this.pathProteccion;
          this.colores = this.customizaciones.proteccion;
          this.pruebas = false;
          break;
        case "https://altas.adelopd.com":
          this.url = this.urlAdelopd;
          this.path = this.pathAdelopd;
          this.colores = this.customizaciones.adelopd;
          this.titulo = "CUESTIONARIO GENERAL RGPD ADELOPD";
          this.pruebas = false;
          break;
        case "https://altas.galanasociados.com":
          this.url = this.urlGalan;
          this.path = this.pathGalan;
          this.colores = this.customizaciones.galan;
          this.titulo = "CUESTIONARIO GENERAL RGPD GALAN ASOCIADOS";
          this.pruebas = false;
          break;

        default:
          this.url = this.urlPruebas;
          this.path = this.pathPruebas;
          this.pruebas = true;
          this.colores = this.customizaciones.adelopd;
          this.titulo = "CUESTIONARIO GENERAL RGPD Pruebas";
          this.pruebas = true;
          break;
      }
    },
  },

  mounted() {
    
    let me = this;
    this.setUrl();
    // //miramos desde que url estamos accediendo
    // if (window.location.origin == "https://altas.protecciondatos.online") {
    //   //si la url es la de proteccion... la var url pasa a valer lo que vale la url de proteccion y el titulo cambia
    //   this.url = this.urlProteccion;
    //   this.titulo = "CUESTIONARIO GENERAL RGPD PROTECCIÓN DATOS";
    //   this.path = this.pathProteccion;
    //   this.colores = this.customizaciones.proteccion;
    //   this.pruebas = false;
    // } else if (window.location.origin == "https://altas.adelopd.com") {
    //   //si la url es la de adelopd... la var url pasa a valer lo que vale la url de adelopd y el titulo cambia
    //   this.url = this.urlAdelopd;
    //   this.path = this.pathAdelopd;
    //   this.colores = this.customizaciones.adelopd;
    //   this.titulo = "CUESTIONARIO GENERAL RGPD ADELOPD";
    //   this.pruebas = false;
    // } else if (window.location.origin == "https://altas.galanasociados.com") {
    //   //si la url es la de adelopd... la var url pasa a valer lo que vale la url de adelopd y el titulo cambia
    //   this.url = this.urlGalan;
    //   this.path = this.pathGalan;
    //   this.colores = this.customizaciones.galan;
    //   this.titulo = "CUESTIONARIO GENERAL RGPD GALAN ASOCIADOS";
    //   this.pruebas = false;
    // } else {
    //   //si la url es la de pruebas... la var url pasa a valer lo que vale la url de adelopd y el titulo cambia
    //   this.url = this.urlPruebas;
    //   this.path = this.pathPruebas;
    //   this.pruebas = true;
    //   this.colores = this.customizaciones.adelopd;
    //   this.titulo = "CUESTIONARIO GENERAL RGPD Pruebas";
    // }
    //miramos si la url lleva /ver y cambia la variable
    if (window.location.hash == "/ver") {
      this.ver = true;
    } else {
      this.ver = false;
    }
    //miramos si hay parametros o si los que hay son diferentes a loos guardados
    if (
      this.$route.params.id != null &&
      this.$route.params.token != null &&
      this.$route.params.token != localStorage.token &&
      this.$route.params.id != localStorage.idFormulario
    ) {
      //si se de que hay parametros diferentes a los guardados se ejecutará esto
      //guarda los parametros en el local storage y luego en las variables

      localStorage.token = this.$route.params.token;
      localStorage.idFormulario = this.$route.params.id;
      this.tokenRecuperar = localStorage.token;
      this.idFormularioRecuperar = localStorage.idFormulario;
      //ejecuta la funcion recuperar formulario
      //this.recuperarFormulario();
      /* if (me.tokenRecuperar != "" && me.idFormularioRecuperar != "") {
      }
      localStorage.token = me.tokenRecuperar;
      localStorage.idFormulario = me.idFormularioRecuperar;
      location = me.path;
      location.reload();*/
    }
    if (this.$route.params.sector != null) {
      this.sector = this.$route.params.sector;
      this.sectorLock = true;
    }
    //se crea el token aleatorio
    this.token = parseInt(Math.random() * 10000000, 10);
    // se verifica si hay un token guardado para su recupoeracion
    this.sectores();
    if (localStorage.token) {
      this.token = localStorage.token;
      this.idFormulario = localStorage.idFormulario;

      this.axios
        .get(`${me.url}todos/${me.idFormulario}/${me.token}`, {
          params: {
            token: me.token,
            id: me.idFormulario,
          },
        })
        .then(function(response) {
          let datos = response.data.data;
          me.datos.estado = JSON.parse(datos.estado);
          me.datos.paso1 = JSON.parse(datos.paso1);
          me.datos.paso2 = JSON.parse(datos.paso2);
          me.datos.paso3 = JSON.parse(datos.paso3);
          me.datos.paso4 = JSON.parse(datos.paso4);
          me.datos.paso5 = JSON.parse(datos.paso5);
          me.datos.paso6 = JSON.parse(datos.paso6);
          me.datos.paso7 = JSON.parse(datos.paso7);
          me.datos.paso8 = JSON.parse(datos.paso8);
          if (me.datos.paso1 == null) {
            me.step = 1;
          } else if (me.datos.paso2 == null) {
            me.step = 2;
          } else if (me.datos.paso3 == null) {
            me.step = 3;
          } else if (me.datos.paso4 == null) {
            me.step = 4;
          } else if (me.datos.paso5 == null) {
            me.step = 5;
          } else if (me.datos.paso6 == null) {
            me.step = 6;
          } else if (me.datos.paso7 == null) {
            me.step = 7;
          } else if (me.datos.paso8 == null) {
            me.step = 8;
          }
          if (me.datos.version != me.formVersion) {
            Swal.fire(
              "Formulario antiguo",
              "Este formulario corresponde a una versión antigua del mismo y puede que falten datos",
              "warning"
            );
          }
          if (me.datos.estado == 1 || me.datos.estado == 2) {
            Swal.fire(
              "Formulario en tramites",
              "Este formulario ya ha sido enviado por lo que los cambios no serán efectivos",
              "warning"
            );
            if (location.href == me.path) {
              location = me.path + "ver";
              location.reload();
            }
          }
          if (me.datos.estado == 0) {
            if (location.href == me.path + "ver") {
              location = me.path;
              location.reload();
            }
          }
          if (me.datos.estado == 3) {
            Swal.fire({
              type: "error",
              title: "Oops...",
              text:
                "El formulario que intentas recuperar fue descartado previamente",
            });
            setTimeout(function() {
              me.nuevo();
            }, 5000);
          }
        });
    } else {
      localStorage.token = this.token;
      Vue.axios
        .post(me.url + "createForm", {
          token: me.token,
          formVersion: me.formVersion,
        })
        .then(function(response) {
          me.idFormulario = response.data.last_insert_id;
          localStorage.idFormulario = me.idFormulario;
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  },
};
</script>
